import React from "react";
import { Link } from "react-router-dom";

const $ = window.jQuery;

export default class Project extends React.Component {
  constructor(props) {
    super(props);

    this.overlay = null;
    this.heading = null;
    this.animating = false;
  }

  onHoverIn = e => {
    const project = $(e.currentTarget);

    this.animating = true;

    this.overlay = project.find(".project-overlay").transition({
      translate: [0, 0],
      duration: 400,
      easing: "cubic-bezier(.23,1,.32,1)",
      complete: () => {
        this.heading = project.find(".project-heading").transition({
          translate: [0, 0],
          opacity: 1,
          duration: 500,
          easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
          complete: () => {
            this.animating = false;
          },
        });
      },
    });
  };

  onHoverOut = e => {
    const project = $(e.currentTarget);

    if (this.heading) {
      this.heading.clearQueue();
    }
    if (this.overlay) {
      this.overlay.clearQueue();
    }

    const animateOut = () => {
      project.find(".project-heading").transition({
        translate: [0, 50],
        opacity: 0,
        duration: 400,
        easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
        complete: () => {
          project.find(".project-overlay").transition({
            translate: [-project.width(), 0],
            duration: 300,
            easing: "cubic-bezier(.23,1,.32,1)",
          });
        },
      });
    };

    if (this.animating) {
      setTimeout(animateOut, 900);
    } else {
      animateOut();
    }
  };

  render() {
    const { heading, description, image, routePath, url, index } = this.props;

    const Wrapper = ({ children }) =>
      routePath ? (
        <Link to={routePath}>{children}</Link>
      ) : (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          alt="View project"
        >
          {children}{" "}
        </a>
      );

    return (
      <div
        className={`project inn project--${index}`}
        data-speed={index * 1.5}
        data-margin={index * 100}
        onMouseEnter={this.onHoverIn}
        onMouseLeave={this.onHoverOut}
      >
        <Wrapper>
          <div className="project-heading">
            <h4>{heading}</h4>
            <div className="line" />
            <p>{description}</p>
          </div>
          <div className="project-overlay" />
          <img src={image} className="project-img" alt="project-overview" />
        </Wrapper>
      </div>
    );
  }
}
