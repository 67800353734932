import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Overview from "./Overview";
import Taskly from "./components/Taskly";
import Oublier from "./components/Oublier";
import BabysDream from "./components/Babys-dream";

class Portfolio extends React.Component {
  render() {
    return (
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} classNames="fade" timeout={300}>
                <Switch location={location}>
                  <Route exact path="/" component={Overview} />
                  <Route path="/Taskly" component={Taskly} />
                  <Route path="/Oublier" component={Oublier} />
                  <Route path="/Babys-dream" component={BabysDream} />
                  <Route path="/:overview" component={Overview} />
                  <Redirect to="/" />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    );
  }
}

export default Portfolio;
