import React from "react";
import { Link } from "react-router-dom";
import { bodyRecalculate, isMobile } from "../core";

const $ = window.jQuery;

export default class Taskly extends React.Component {
  state = {
    isMobile: false,
  };

  componentDidMount() {
    setTimeout(this.pageAnimations, 500);

    this.setState({
      isMobile: isMobile(),
    });
  }

  pageAnimations() {
    bodyRecalculate();

    $(".taskly .project-logo").transition({
      scale: 1,
      opacity: 1,
      duration: 400,
      easing: "out",
      complete: () => {
        $(".taskly .project-heading").transition({
          translate: [0, 0],
          opacity: 1,
          duration: 500,
          easing: "cubic-bezier(.23,1,.32,1)",
          complete: () => {
            $(".taskly .project-description").each(function () {
              $(this).transition({
                translate: [0, 0],
                opacity: 1,
                duration: 800,
                easing: "cubic-bezier(.23,1,.32,1)",
              });
            });

            $(".taskly .project-photo").each(function () {
              $(this).transition({
                translate: [0, 0],
                opacity: 1,
                duration: 800,
                easing: "cubic-bezier(.23,1,.32,1)",
              });
            });
          },
        });
      },
    });
  }

  render() {
    const { isMobile } = this.state;

    return (
      <div className="taskly project-overview">
        <Link to="/overview" className="stn-btn">
          Back
        </Link>
        <div className="flex-row project-main">
          <div className="project-logo">
            <img
              src={require("../img/Taskly-logo.png")}
              alt="Taskly application"
            />
          </div>
          <div className="project-heading">
            <h2>Taskly</h2>
            <div className="line" />
            <span>Mobile design | Mobile development </span>
          </div>
        </div>
        <div className={isMobile ? "flex-col" : "flex-row"}>
          <div className="project-description">
            <h4>Go through your day with ease</h4>
            <p>
              This easy-to-use yet advanced task manager helps you reach your
              goals faster and better.
            </p>
            <p>
              Displays simplicity and ease of use. Whether you want a shopping
              list, grocery list or you just have lots of things to remember
              this app is built just for you.
            </p>
          </div>
          <div className="project-photo">
            <img
              src={require("../img/Taskly-1.png")}
              alt="Taskly tasks screen"
            />
          </div>
        </div>
        <div className={isMobile ? "flex-col" : "flex-row"}>
          <div
            className={`project-photo ${
              !isMobile ? "project-photo--left" : ""
            }`}
          >
            <img
              src={require("../img/Taskly-2.png")}
              alt="Taskly create task screen"
            />
          </div>
          <div
            className={`project-description ${
              !isMobile ? "project-description--right" : ""
            }`}
          >
            <h4>Features</h4>
            <ul>
              <li>Includes six different themes.</li>
              <li> Notifications keep you in check with your tasks</li>
              <li>
                Data synchronization - login with your Google account to sync
                your tasks.
              </li>
              <li>Resizable home screen widget</li>
            </ul>
            <div className="gplay">
              <a
                href="https://play.google.com/store/apps/details?id=com.taskly&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Get Taskly on Google Play"
                  src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
