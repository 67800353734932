import { isMobile, inertiaScroll } from "./core";

const $ = window.jQuery;

const ProgressBar = window.ProgressBar;

class Home {
  constructor() {
    this.handlePageAnimations = this.handlePageAnimations.bind(this);

    this.bindEvents();
    this.documentReadyState();
  }

  bindEvents() {
    $(document).ready(() => {
      this.subscribeToResize();
      this.initProgressBar();
      this.pageScrollingEvents();
      this.animationEvents();
      this.navigation();
      this.bindWhatIDoSectionEvents();

      $(window).on("touchmove scroll", this.handlePageAnimations);

      const inputs = $(".form-control");

      inputs.focus(this.toggleContactOverlay);

      $(document).click(".footer, #contact, #portfolio", e => {
        if ($(e.target).hasClass("form-control")) {
          return;
        }

        this.toggleContactOverlay(false);
      });

      $(".navbar-collapse ul li a").click(function () {
        $(".navbar-toggle:visible").click();
      });

      // Disable contextmenu
      $("body").on("contextmenu", function (e) {
        return false;
      });

      // Disable img dragging
      $("img").on("dragstart", function (e) {
        e.preventDefault();
      });

      $("#messageMe").submit(() => {
        this.sendMessage();

        return false;
      });
    });
  }

  bindWhatIDoSectionEvents() {
    let animatingMask = null;
    let animatingListItem = null;
    let shouldHidePhoto = true;

    $("#what-i-do .item span").each(function (index) {
      const listItem = $(`#what-i-do .item-bg--${index + 1}`);
      const mask = $("#what-i-do .item--mask");

      $(this).hover(
        () => {
          if (animatingMask) {
            animatingMask.clearQueue();
            animatingListItem.clearQueue();
            animatingListItem.css("opacity", 0);
            mask.transition({
              translate: [0, 0],
              duration: 500,
              easing: "cubic-bezier(.23,1,.32,1)",
              complete: () => {
                animatingListItem = null;
                animatingMask = null;
              },
            });
          }

          animatingListItem = listItem.transition({
            opacity: 0.6,
            duration: 1200,
            easing: "out",
          });

          animatingMask = mask.transition({
            translate: [-mask.width(), 0],
            duration: 600,
            easing: "cubic-bezier(.23,1,.32,1)",
          });
        },
        () => {
          if (!shouldHidePhoto) {
            return;
          }

          listItem.transition({
            opacity: 0,
            duration: 300,
            easing: "in-out",
          });

          mask.transition({
            translate: [0, 0],
            duration: 800,
            easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
            complete: () => {
              animatingListItem = null;
              animatingMask = null;
            },
          });
        }
      );
    });

    $("#what-i-do .item span").each(function (index) {
      const listItem = $(`#what-i-do .item-info--${index + 1}`);

      $(this).click(() => {
        shouldHidePhoto = false;

        listItem.transition({
          translate: [0, 0],
          duration: 800,
          easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
          complete: () => {
            $("#what-i-do .close-item span").transition({
              opacity: 0.7,
              duration: 400,
              easing: "out",
            });
          },
        });
      });
    });

    $("#what-i-do .close-item span").click(function () {
      const listItem = $(this).closest(`#what-i-do .item-info`);

      shouldHidePhoto = true;

      $(this).transition({
        opacity: 0,
        duration: 200,
        easing: "out",
        complete: () => {
          $(this).css("transform", "rotate(0) translateZ(0)");
        },
      });

      listItem.transition({
        translate: [-listItem.width(), 0],
        duration: 300,
        easing: "out",
      });

      const index = listItem.attr("class").split("--")[1];
      const itemBg = $(`#what-i-do .item-bg--${index}`);
      const mask = $("#what-i-do .item--mask");

      itemBg.transition({
        opacity: 0,
        duration: 300,
        easing: "in-out",
      });

      mask.transition({
        translate: [0, 0],
        duration: 800,
        easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
      });
    });
  }

  sendMessage() {
    const form = $("#messageMe");
    const inputs = form.find("input");
    const textArea = form.find("textarea");
    const data = {
      name: inputs.eq(0).val(),
      email: inputs.eq(1).val(),
      subject: inputs.eq(2).val(),
      message: textArea.val(),
    };

    if (!data.name || !data.email || !data.subject || !data.message) return;

    const alert = form.find(".alert");
    const submitBtn = form.find(".stn-btn");

    // disable submit button
    submitBtn.attr("disabled", true);

    $.post({
      url: "/messages",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data),
      success: res => {
        alert.text(res.message);
        if (res.success) {
          $("form button").remove();
          alert.addClass("alert-success").show();
          $("#messageMe :input").attr("readonly", "readonly");

          $(".form-control").off("focus", this.toggleContactOverlay());

          setTimeout(() => {
            alert.hide();
          }, 4000);
        } else {
          submitBtn.attr("disabled", false);
          alert.addClass("alert-danger").show();
        }
      },
      error: () => {
        submitBtn.attr("disabled", false);
        alert.text("Failed to submit.");
        alert.addClass("alert-danger").show();
      },
    });
  }

  toggleContactOverlay(isOpen = true) {
    const overlay = $("#contact > .overlay");
    const isOpened = overlay.hasClass("opened");
    let opacity = 0;

    if (isOpened && isOpen) {
      return;
    } else if (!isOpened && !isOpen) {
      return;
    }

    if (isOpen) {
      overlay.show();
      opacity = 1;
    }

    overlay.toggleClass("opened");

    overlay.transition({
      opacity,
      duration: 300,
      easing: "in-out",
      complete: () => {
        if (!isOpen) {
          overlay.hide();
        }
      },
    });
  }

  isInView(el) {
    const jqEl = $(el);

    if (!jqEl.length) return;

    const win = $(window);
    const winHeight = win.height();
    const viewport = {
      top: win.scrollTop(),
      left: win.scrollLeft(),
      bottom: win.scrollTop() + winHeight,
    };

    const elOffset = jqEl.offset();
    const bottomDelta = 100;
    elOffset.bottom = elOffset.top + jqEl.height() - bottomDelta;

    return elOffset.top >= viewport.top && elOffset.bottom <= viewport.bottom;
  }

  handlePageAnimations() {
    if ($("#contact > .overlay").hasClass("opened")) {
      this.toggleContactOverlay(false);
    }

    if (this.animating) {
      return;
    }

    if (this.isInView("#about .pane")) {
      this.triggerAboutAnimation();
    }
    if (this.isInView("#skills .skills-panel")) {
      this.triggerSkillsAnimation();
    }
    if (this.isInView("#what-i-do")) {
      this.triggerWhatIDoAnimation();
    }
    if (this.isInView("#portfolio .pane")) {
      this.triggerPortfolioAnimation();
    }
    if (this.isInView("#portfolio .project")) {
      this.triggerPortfolioSecondAnimation();
    }
    if (this.isInView("#contact")) {
    }
  }

  triggerAboutAnimation() {
    if (this.aboutCompleted) {
      return;
    }

    this.aboutCompleted = true;

    const pane = $("#about .pane");
    const screenWidth = $(window).width();

    this.animating = true;

    pane
      .transition({
        width: screenWidth * 0.8,
        duration: 600,
        easing: "in-out",
      })
      .transition({
        width: isMobile() ? screenWidth : screenWidth * 0.75,
        duration: 300,
        easing: "out",
        complete: () => {
          const textList = pane.find(".text-list");

          textList.show().transition({
            opacity: 1,
            duration: 400,
            easing: "in-out",
          });
          this.animating = false;
        },
      });
  }

  triggerSkillsAnimation() {
    if (this.skillsCompleted) {
      return;
    }

    this.animating = true;
    this.skillsCompleted = true;

    const panelsWrapper = $(".skills-panel-wrapper");
    const pane = $("#skills .pane");
    const screenWidth = $(window).width();

    panelsWrapper.transition({
      translate: [0, 0],
      scale: 1,
      opacity: 1,
      duration: 400,
      easing: "in-out",
      complete: () => {
        pane.transition({
          width: screenWidth * 0.6,
          duration: 600,
          easing: "in-out",
        });

        this.animating = false;
      },
    });
  }

  triggerWhatIDoAnimation() {
    if (this.whatIDoCompleted) {
      return;
    }

    this.animating = true;
    this.whatIDoCompleted = true;

    const section = $("#what-i-do");

    section.transition({
      translate: [0, 0],
      opacity: 1,
      duration: 800,
      easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
    });

    section.find(".list").transition({
      translate: [0, 0],
      opacity: 1,
      duration: 800,
      easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
      complete: () => (this.animating = false),
    });
  }

  triggerPortfolioAnimation() {
    if (this.portfolioCompleted) {
      return;
    }

    this.portfolioCompleted = true;
    this.animating = true;

    const pane = $("#portfolio .pane");
    const screenWidth = $(window).width();

    pane.transition({
      width: isMobile() ? screenWidth : screenWidth * 0.75,
      duration: 600,
      easing: "out",
      complete: () => {
        const h2 = pane.find("h2");

        h2.show().transition({
          opacity: 1,
          duration: 400,
          easing: "in-out",
        });

        const projects = $(".project");

        projects.eq(0).transition({
          translate: [0, 0],
          opacity: 1,
          duration: 800,
          easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
        });

        projects.eq(1).transition({
          translate: [0, 0],
          opacity: 1,
          duration: 800,
          easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
          complete: () => (this.animating = false),
        });
      },
    });
  }

  triggerPortfolioSecondAnimation() {
    if (this.portfolioSecondCompleted) {
      return;
    }

    this.portfolioSecondCompleted = true;
    this.animating = true;

    const projects = $(".project");

    projects.eq(2).transition({
      translate: [0, 0],
      opacity: 1,
      duration: 800,
      easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
    });

    projects.eq(3).transition({
      translate: [0, 0],
      opacity: 1,
      duration: 800,
      easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
    });

    projects.eq(4).transition({
      translate: [0, 0],
      opacity: 1,
      duration: 800,
      easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
      complete: () => (this.animating = false),
    });
  }

  documentReadyState() {
    return (document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        window.history.pushState("", document.title, window.location.pathname);
        setTimeout(() => {
          this.removeProgressBar();
        }, 4000);
      }
    });
  }

  displayPage() {
    $(".app").fadeIn();
  }

  initProgressBar() {
    $("body").addClass("no-scroll");
    $("#progressbar").css("opacity", 1);

    let bar = new ProgressBar.Path("#stundji-path", {
      color: "rgb(255, 255, 255)",
      easing: "easeInOut",
      duration: 6000,
    });

    bar.set(0);
    bar.animate(1.0);
    setTimeout(() => $("#stundji-path").css("fill", "#fff"), 3000);
  }

  removeProgressBar() {
    let self = this;
    let progressBarWrapper = $("#progressbar-wrapper");

    inertiaScroll();
    progressBarWrapper.find("div").transition({
      opacity: 0,
      duration: 500,
      easing: "out",
      complete: function () {
        self.displayPage();
        progressBarWrapper.transition({
          width: 0,
          duration: 600,
          easing: "in-out",
          complete: function () {
            $(".homeHeading-wrapper").transition({
              translate: [0, 0],
              opacity: 1,
              duration: 600,
              easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
            });
            $(".homeHeading-wrapper .pane").transition({
              width: $(".signature").width(),
              duration: 600,
              easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
            });

            $(".header-content .box")
              .eq(0)
              .transition({
                width: isMobile() ? 75 : 100,
                opacity: 1,
                duration: 1000,
                easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
              });

            $(".header-content .box")
              .eq(1)
              .transition({
                width: isMobile() ? 150 : 200,
                opacity: 1,
                duration: 1000,
                easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
              });

            $(".signature-wrapper").transition({
              translate: [0, 0],
              opacity: 1,
              duration: 800,
              easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
            });

            $(".header-content .overlay")
              .eq(1)
              .transition({
                width: $("#header").width() * 0.8,
                duration: 800,
                easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
                complete: () => {
                  $("body").removeClass("no-scroll");
                },
              });
          },
        });
      },
    });
  }

  pageScrollingEvents() {
    $(document).on("click", "a.page-scroll", e => {
      e.preventDefault();
      const anchor = $(e.currentTarget);

      if (anchor.parent().is("li")) {
        this.toggleNavigation(false);
      }

      const yPos = $(anchor.attr("href")).offset().top;

      window.scrollTo(0, yPos);
    });
  }

  navigation() {
    let self = this;
    const navButton = $(".open-overlay");

    navButton.on("click", () => {
      if (navButton.hasClass("opened")) {
        self.toggleNavigation(false);
      } else {
        self.toggleNavigation(true);
      }
    });
  }

  toggleNavigation(isOpen) {
    const header = $("#header");
    const headerWidth = header.innerWidth();
    let top_bar = $(".bar-top");
    let middle_bar = $(".bar-middle");
    let bottom_bar = $(".bar-bottom");
    let nav = $(".overlay-navigation");
    let menu = $(".nav");
    let navLine = $(".nav-line");
    let navBox = $(".nav-box");

    if (isOpen) {
      top_bar.removeClass("animate-out-top-bar").addClass("animate-top-bar");
      middle_bar
        .removeClass("animate-out-middle-bar")
        .addClass("animate-middle-bar");
      bottom_bar
        .removeClass("animate-out-bottom-bar")
        .addClass("animate-bottom-bar");

      nav.transition({
        width: headerWidth,
        opacity: 1,
        duration: 400,
        easing: "in",
        complete: function () {
          menu.transition({
            opacity: 1,
            translate: [0, 0],
            duration: 300,
            easing: "out",
            complete: () => {
              navLine.transition({
                width: isMobile() ? headerWidth / 1.5 : headerWidth / 2.5,
                duration: 300,
                easing: "out",
              });
            },
          });
          $(".nav-social").transition({
            opacity: 1,
            duration: 200,
            easing: "in-out",
          });

          navBox.transition({
            width: headerWidth / 6.66,
            easing: "out",
          });
        },
      });
    } else {
      top_bar.removeClass("animate-top-bar").addClass("animate-out-top-bar");
      middle_bar
        .removeClass("animate-middle-bar")
        .addClass("animate-out-middle-bar");
      bottom_bar
        .removeClass("animate-bottom-bar")
        .addClass("animate-out-bottom-bar");

      navLine.transition({
        width: 0,
        duration: 300,
        easing: "out",
        complete: () => {
          nav.transition({
            width: 0,
            opacity: 0,
            duration: 400,
            easing: "in-out",
          });

          //menu
          menu.transition({
            opacity: 0,
            translate: [-menu.innerWidth(), 0],
            duration: 400,
            easing: "in",
          });

          $(".nav-social").transition({
            opacity: 0,
            duration: 300,
            easing: "in-out",
          });

          navBox.transition({
            width: 0,
            easing: "out",
          });
        },
      });
    }

    $(".open-overlay").toggleClass("opened");
  }

  bindNavigationEvents() {
    $(".nav li").hover(
      e => {
        $(e.currentTarget).addClass("expand-bar");
        $(".overlay-background").removeClass("hidden");
      },
      e => {
        $(e.currentTarget).removeClass("expand-bar");
        $(".overlay-background").addClass("hidden");
      }
    );
  }

  unbindNavigationEvents() {
    $(".nav li").unbind("mouseenter mouseleave");
  }

  animationEvents() {
    $(".footer-social .soc-box").hover(
      e => {
        $(e.currentTarget).addClass("animated pulse").css("opacity", 1);
      },
      e => {
        $(e.currentTarget).removeClass("animated pulse").css("opacity", 0.7);
      }
    );

    $("#what-i-do .close-item span").hover(
      e => {
        $(e.currentTarget).css("transform", "rotate(90deg) translateZ(1px)");
      },
      e => {
        $(e.currentTarget).css("transform", "rotate(0) translateZ(0)");
      }
    );
  }

  onResize = () => {
    this.aboutCompleted = false;
    this.whatIDoCompleted = false;
    this.skillsCompleted = false;
    this.portfolioCompleted = false;

    $("body").height($(".app").height());
    this.handlePageAnimations();
  };

  subscribeToResize = () => {
    let debounce = null;

    $(window).resize(() => {
      clearTimeout(debounce);

      debounce = setTimeout(this.onResize, 400);
    });
  };
}

new Home();
