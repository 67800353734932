import React from "react";
import { Link } from "react-router-dom";
import { isMobile, bodyRecalculate } from "../core";
import babysDreamLogo from "../img/svg/Babys-dream-Logo.svg";

const $ = window.jQuery;

export default class BabysDream extends React.Component {
  state = {
    isMobile: false,
  };

  componentDidMount() {
    setTimeout(this.pageAnimations, 500);

    this.setState({
      isMobile: isMobile(),
    });
  }

  pageAnimations() {
    bodyRecalculate();

    const root = $(".babys-dream");

    root.find(".project-main").transition({
      translate: [0, 0],
      opacity: 1,
      duration: 600,
      easing: "cubic-bezier(.23,1,.32,1)",
      complete: () => {
        root.find(".project-logo").transition({
          scale: 1,
          opacity: 1,
          duration: 400,
          easing: "out",
          complete: () => {
            root.find(".project-heading").transition({
              translate: [0, 0],
              opacity: 1,
              duration: 500,
              easing: "cubic-bezier(.23,1,.32,1)",
              complete: () => {
                root.find(".project-description").each(function () {
                  $(this).transition({
                    translate: [0, 0],
                    opacity: 1,
                    duration: 800,
                    easing: "cubic-bezier(.23,1,.32,1)",
                  });
                });

                root.find(".project-photo").each(function () {
                  $(this).transition({
                    translate: [0, 0],
                    opacity: 1,
                    duration: 800,
                    easing: "cubic-bezier(.23,1,.32,1)",
                  });
                });
              },
            });
          },
        });
      },
    });
  }

  render() {
    const { isMobile } = this.state;

    return (
      <div className="babys-dream project-overview">
        <Link to="/overview" className="stn-btn">
          Back
        </Link>
        <div className="flex-row project-main">
          <div className="project-logo">
            <img src={babysDreamLogo} alt="Babys dream" />
          </div>
          <div className="project-heading">
            <h2>Babys' Dream</h2>
            <div className="line" />
            <span>Web design | Animations | Photography </span>
          </div>
        </div>
        <div className={`photo-row ${isMobile ? "flex-col" : "flex-row"}`}>
          <div className="project-photo">
            <img
              src={require("../img/Babys-dream-1.png")}
              alt="Babys dream navigation menu"
            />
          </div>
          <div className="project-description">
            <h4>A love story</h4>
            <p>
              Babys dream is all about the relationship between me and my baby
              girl. The site's uniqueness and animations combined with the
              beautiful photos result in a very pleasant experience.
            </p>
          </div>
        </div>
        <div className={`photo-row ${isMobile ? "flex-col" : "flex-row"}`}>
          <div className="project-description">
            <h4>Gallery</h4>
            <p>
              Smooth animations and flowing transitions give the overall
              experience a refined elegance.
            </p>
          </div>
          <div className="project-photo">
            <img
              src={require("../img/Babys-dream-2.png")}
              alt="Babys dream gallery"
            />
          </div>
        </div>
        <div className={`photo-row ${isMobile ? "flex-col" : "flex-row"}`}>
          <div className="project-photo">
            <img
              src={require("../img/Babys-dream-3.png")}
              alt="Babys dream music player"
            />
          </div>
          <div className="project-description">
            <h4>Music player</h4>
            <p>Includes a music player with playlist of our favourite songs.</p>
          </div>
        </div>
        <div className={`photo-row ${isMobile ? "flex-col" : "flex-row"}`}>
          <div className="project-description">
            <p>
              Check it out at{" "}
              <a
                href="http://www.babys-dream.life"
                target="_blank"
                rel="noreferrer noopener"
              >
                Baby's Dream
              </a>
            </p>
          </div>
          <div className="project-photo">
            <img
              src={require("../img/Babys-dream-4.png")}
              alt="Babys dream header"
            />
          </div>
        </div>
      </div>
    );
  }
}
