const $ = window.jQuery;

export function isMobile() {
  let width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

  if (width < 1024) {
    return width;
  } else {
    return false;
  }
}

export function inertiaScroll() {
  return $(".inn").inertiaScroll({
    parent: $(".app"),
    parentDelta: 0.05,
  });
}

export function bodyRecalculate() {
  $("body").height($(".app").height());

  const portfolioTop = $("#portfolio").offset().top;

  window.scrollTo(0, portfolioTop);
}
