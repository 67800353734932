import React from "react";
import Project from "./components/Project";
import { isMobile, bodyRecalculate } from "./core";

const $ = window.jQuery;

export default class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.projects = [
      {
        heading: "Taskly",
        description: "Go through your day with ease",
        image: require("./img/Taskly.jpg"),
        routePath: "/Taskly",
      },
      {
        heading: "Oublier",
        description:
          "The phenomenon of forgetting through our use of everyday objects",
        image: require("./img/Oublier.jpg"),
        routePath: "/Oublier",
      },
      {
        heading: "Babys' dream",
        description: "A love story",
        image: require("./img/Babys-dream.png"),
        routePath: "/Babys-dream",
      },
      {
        heading: "Bexio",
        description: "Contact app",
        image: require("./img/Bexio.png"),
        url: "https://play.google.com/store/apps/details?id=com.bexiocontacts",
      },
      {
        //TODO: implement Reimburse Me route "reimburse-me.js"
        heading: "Reimburse Me",
        description:
          "Receive compensation up to €600 if your flight was cancelled, delayed or overbooked.",
        image: require("./img/ReimburseMe.png"),
        url: "https://reimburseme.eu",
      },
    ];
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params.overview) {
      setTimeout(bodyRecalculate, 350);
      this.showProjectAnimations();
    }
  }

  showProjectAnimations() {
    const pane = $("#portfolio .pane");
    const screenWidth = $(window).width();

    pane.transition({
      width: isMobile() ? screenWidth : screenWidth * 0.75,
      duration: 600,
      easing: "out",
      complete: () => {
        const h2 = pane.find("h2");

        h2.show().transition({
          opacity: 1,
          duration: 400,
          easing: "in-out",
        });

        const projects = $(".project");

        projects.each(function () {
          $(this).transition({
            translate: [0, 0],
            opacity: 1,
            duration: 800,
            easing: "cubic-bezier(0.375, 0.765, 0.110, 0.975)",
          });
        });
      },
    });
  }

  render() {
    return (
      <div className="overview">
        <div className="pane inn" data-speed="6" data-margin="400">
          <h2>My recent projects</h2>
        </div>
        <div className="box inn" data-speed="4"></div>
        <div className="box inn" data-speed="2"></div>
        <div className="box inn vert" data-speed="2" />
        <div className="wrapper">
          {this.projects.map((project, index) => (
            <Project
              {...project}
              index={index + 1}
              key={project.heading}
              routePath={project.routePath}
            />
          ))}
        </div>
      </div>
    );
  }
}
