import React from "react";
import { Link } from "react-router-dom";
import { isMobile, bodyRecalculate } from "../core";
import oublierLogo from "../img/svg/Oublier-logo.svg";

const $ = window.jQuery;

export default class Oublier extends React.Component {
  state = {
    isMobile: false,
  };

  componentDidMount() {
    setTimeout(this.pageAnimations, 500);

    this.setState({
      isMobile: isMobile(),
    });
  }

  pageAnimations = () => {
    bodyRecalculate();

    const { isMobile } = this.state;
    const pageWidth = $("#portfolio").width();

    $(".oublier .box").transition({
      translate: [0, 0],
      opacity: 1,
      duration: 600,
      easing: "cubic-bezier(.23,1,.32,1)",
      complete: () => {
        $(".oublier .an")
          .show()
          .transition({
            width: isMobile ? pageWidth * 0.9 : pageWidth * 0.7,
            duration: 1000,
            easing: "cubic-bezier(.23,1,.32,1)",
            complete: () => {
              $(".oublier .an h4").transition({
                opacity: 1,
                duration: 600,
                easing: "out",
              });

              $(".oublier .project-description").each(function () {
                $(this).transition({
                  translate: [0, 0],
                  opacity: 1,
                  duration: 600,
                  easing: "out",
                });
              });

              $(".oublier .project-photo").each(function () {
                $(this).transition({
                  translate: [0, 0],
                  opacity: 1,
                  duration: 600,
                  easing: "out",
                });
              });
            },
          });
      },
    });
  };

  render() {
    const { isMobile } = this.state;

    return (
      <div className="oublier project-overview">
        <Link to="/overview" className="stn-btn">
          Back
        </Link>
        <div className={!isMobile ? "flex-row" : ""}>
          <div className="box">
            <div className="project-logo">
              <img src={oublierLogo} alt="Oublier" />
            </div>
          </div>
          <div className={`flex-row an ${!isMobile ? "absolute-row" : ""}`}>
            <h4>
              The phenomenon of forgetting through our use of everyday objects
            </h4>
          </div>
        </div>
        <div className="flex-row">
          <div className="project-photo">
            <img
              src={require("../img/Oublier-1.png")}
              alt="Oublier work screen"
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="project-description">
            <h4>Simplicity over complexity</h4>
          </div>
        </div>
        <div className="flex-row">
          <div className="project-photo project-photo--relative">
            <img
              src={require("../img/Oublier-2.png")}
              alt="Oublier mobile animation"
            />
          </div>
          <div className="project-description">
            <p>
              Oublier delivers rare, refined products with a great attention to
              details. I collaborated with Oublier to deliver simple, yet
              beautiful, eye-catching website.
            </p>
            <p>
              See more at{" "}
              <a
                href="https://oublier.bg"
                target="_blank"
                rel="noreferrer noopener"
              >
                Oublier.bg
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
